import { Suspense, useState, useMemo, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom';
import { Canvas } from '@react-three/fiber'
import {
  CameraControls,
  PerspectiveCamera,
  Environment,
  useProgress,
} from '@react-three/drei';
import { ChromePicker } from 'react-color'
import * as THREE from "three";
import { debounce } from 'lodash';

import jflogo from '../../assets/jflogo.png';
// import brakeModel from '../../assets/brake/brakes_basesize.glb';

import IconSpray from '../icons/IconSpray';
import IconSuspension from '../icons/IconSuspension';
import IconGallery from '../icons/IconGallery';
import IconThreesixty from '../icons/IconThreesixty';

import Car from '../Car';
import WheelAndDots from '../WheelAndDots';
import Background from '../Background';
import { EnvBuilder } from '../EnvBuilder';
import ClickOutside from '../ClickOutside';

import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';

import PropTypes from 'prop-types';

const HDRI_LIST = {
  'canary_wharf': require('../../assets/hdri/canary_wharf_1k.hdr'),
  'canary_wharf_4k': require('../../assets/hdri/canary_wharf_4k.hdr'),
};

const BG_LIST= [
  {
    key: 'empty',
  },
  {
    key: 'canary',
    bg: require('../../assets/bg-optimize/canary_wharf/model.glb'),
    img: require('../../assets/bg-optimize/canary_wharf/cover.webp'),
    hdri: require('../../assets/bg-optimize/canary_wharf/hdr.hdr'),
    hdrJpg: require('../../assets/bg-optimize/canary_wharf/hdr-jpg.jpg'),
    ground: {
      height: 5.5, // Height of the camera that was used to create the env map (Default: 15)
      radius: 55, // Radius of the world. (Default 60)
      scale: 100, // Scale of the backside projected sphere that holds the env texture (Default: 1000)
    }
  },
  {
    key: 'el_playazo',
    bg: require('../../assets/bg-optimize/el_playazo/model.glb'),
    img: require('../../assets/bg-optimize/el_playazo/cover.webp'),
    hdri: require('../../assets/bg-optimize/el_playazo/hdr.hdr'),
    hdrJpg: require('../../assets/bg-optimize/el_playazo/hdr-jpg.jpg'),
    ground: {
      height: 4.5, // Height of the camera that was used to create the env map (Default: 15)
      radius: 65, // Radius of the world. (Default 60)
      scale: 120, // Scale of the backside projected sphere that holds the env texture (Default: 1000)
    }
  },
  {
    key: 'freight_station',
    bg: require('../../assets/bg-optimize/freight-station/model.glb'),
    img: require('../../assets/bg-optimize/freight-station/cover.webp'),
    hdri: require('../../assets/bg-optimize/freight-station/hdr.hdr'),
    hdrJpg: require('../../assets/bg-optimize/freight-station/hdr-jpg.jpg'),
    ground: {
      height: 3, // Height of the camera that was used to create the env map (Default: 15)
      radius: 50, // Radius of the world. (Default 60)
      scale: 120, // Scale of the backside projected sphere that holds the env texture (Default: 1000)
    }
  },
]

const CAR_OPTIONS_LIST = [
  {
    key: 'mercedes',
    img: require('../../assets/e_class_w214_25_1.jpg'),
    file: require('../../assets/cars/no-interior/11867_mercedes_e-class_2024_001.glb'),
    name: 'Mercedes E Class 2024',
    diameter: 18,
    diameterOptions: [18,19,20,21]
  },
  {
    key: 'smart',
    img: require('../../assets/smart.webp'),
    file: require('../../assets/cars/no-interior/11408_Smart_NInterior_001.glb'),
    name: 'Smart EQ',
    diameter: 16,
    diameterOptions: [16,17]
  },
  {
    key: 'skoda',
    img: require('../../assets/skoda-karoq.jpg'),
    file: require('../../assets/cars/no-interior/11685_Skoda_NInterior_001.glb'),
    name: 'Skoda Karoq Facelift',
    diameter: 18,
    diameterOptions: [18,19,20]
  },
  {
    key: 'porsche',
    img: require('../../assets/porsche-taycan.jpg'),
    file: require('../../assets/cars/no-interior/11370_Porsche_NInterior_001.glb'),
    name: 'Porche Taycan',
    diameter: 20,
    diameterOptions: [20,21]
  },
  {
    key: 'ford',
    img: require('../../assets/ford-ranger.jpg'),
    file: require('../../assets/cars/no-interior/11246_Ford_NInterior_001.glb'),
    name: 'Ford Ranger',
    diameter: 17,
    diameterOptions: [17,18,19,20]
  },
  {
    key: 'cupra',
    img: require('../../assets/cupra-leon.jpg'),
    file: require('../../assets/cars/no-interior/11530_Cupra_NInterior_001.glb'),
    name: 'Cupra Formentor',
    diameter: 18,
    diameterOptions: [18,19,20]
  },
]

const WHEEL_OPTIONS_LIST = [
  {
    key: 'itwheels-amos',
    img: require('../../assets/amos.jpg'),
    file: require('../../assets/wheels/itwheels/amos_18_gloss-black-polished.glb'),
  },
  {
    key: 'bbs-lm',
    img: require('../../assets/LM.png'),
    file: require('../../assets/wheels/bbs/lm_18_brillantsilber-felge-diagedr.glb'),
  },
  {
    key: 'bbs-chr',
    img: require('../../assets/CH-R.png'),
    file: require('../../assets/wheels/bbs/ch-r2_18_bronze-matt-schwarz.glb'),
  },
  {
    key: 'bbs-cir',
    img: require('../../assets/CI-R.png'),
    file: require('../../assets/wheels/bbs/ci-r_18_nurburgring-edition.glb'),
  },
  {
    key: 'itwheels-michelle',
    img: require('../../assets/michelle.jpg'),
    file: require('../../assets/wheels/itwheels/michelle_18_matt-black.glb'),
  },
  {
    key: 'itwheels-emily',
    img: require('../../assets/emily.jpg'),
    file: require('../../assets/wheels/itwheels/emily_18_gloss-silver.glb'),
  },
]

const ConfiguratorOne = ({
  // init color
  color,

  // if has then color thumbnail picker, not then color pallete picker
  colormap,
  colorlist,
  paint,

  // has suspension option or not
  suspension,
  // 0 - 10
  susval,

  // has auto rotate or not
  autorotate,

  // has background or not
  background,

  carid,
  wheelid,
  diameter: diameterArgs,

  // onloading event callback
  onloading,
  iscustom,
}) => {
  const [selectedWheelIndex, setSelectedWheelIndex] = useState(0);
  const [selectedCarIndex, setSelectedCarIndex] = useState(0);
  const [selectedWheelKey, setSelectedWheelKey] = useState('');
  const [selectedCarKey, setSelectedCarKey] = useState('');

  const camRef = useRef();
  const cameraControlsRef = useRef();

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const isMobileAndTabletControlVertical = useMemo(() => windowSize.innerWidth < 800 && window.matchMedia('(orientation: portrait)').matches, [windowSize.innerWidth]);
  const isMobileAndTabletHorizontal = useMemo(() => windowSize.innerWidth < 900 && window.matchMedia('(orientation: landscape)').matches, [windowSize.innerWidth]);

  const { progress, loaded } = useProgress();

  const [wheelSize, setWheelSize] = useState(new THREE.Vector3());
  const [dots, setDots] = useState([]);
  const [tyreDepth, setTyreDepth] = useState(0);
  const [tyreNodes, setTyreNodes] = useState([]);
  const [hasDrumBrake, setHasDrumBrake] = useState(false);

  const [selectedBgIndex, setSelectedBgIndex] = useState(0);
  const [selectedPaint, setSelectedPaint] = useState(0);
  const [showDelayLoading, setShowDelayLoading] = useState(true);

  const [showCarSuspension, setShowCarSuspension] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showBgList, setShowBgList] = useState(false);
  const [showBg, setShowBg] = useState(false);

  const [carSuspension, setCarSuspension] = useState(0);
  const [carColor, setCarColor] = useState('#410000');

  const [cameraAutoRotate, setCameraAutoRotate] = useState(false);

  const startDistance = useRef(0);
  const distanceCount = useRef(0);
  const endDistance = useRef(0);

  const wheelScale = useMemo(() => {
    if (!CAR_OPTIONS_LIST[selectedCarIndex]) {
      return 18
    }

    if (!WHEEL_OPTIONS_LIST[selectedWheelIndex]) {
      return CAR_OPTIONS_LIST[selectedCarIndex].diameter
    }

    if (!diameterArgs) {
      return CAR_OPTIONS_LIST[selectedCarIndex].diameter
    }

    return 1 + (parseInt(diameterArgs) - CAR_OPTIONS_LIST[selectedCarIndex].diameter) * 0.04
  }, [diameterArgs, selectedCarIndex, selectedWheelIndex]);


  const colorOptionArray = useMemo(() => {
    if (!colorlist || !colormap) {
      return []
    }
    const colormapArray = colormap.toString().split(',')
    const colorlistArray = colorlist.toString().split(',')
    return colorlistArray.slice().map((item, index) => {
      return {
        value: '#' + item,
        display: '#' + colormapArray[index]
      }
    });
  }, [colorlist, colormap])

  function handleSelectBg(index) {
    setSelectedBgIndex(index);
    setShowBgList(false);
    if (index === 0) {
      setShowBg(false)
      return;
    }
    setShowBg(true)
  }

  useEffect(() => {
    if (selectedCarKey === carid && selectedWheelKey === wheelid) {
      return;
    }

    if (onloading) {
      onloading({
        detail: true
      })
    }
    if (carid && wheelid) {
      setSelectedCarKey(carid)
      setSelectedWheelKey(wheelid)
      const carIndex = CAR_OPTIONS_LIST.findIndex(item => item.key === carid);
      setSelectedCarIndex(carIndex);

      const wheelIndex = WHEEL_OPTIONS_LIST.findIndex(item => item.key === wheelid);
      setSelectedWheelIndex(wheelIndex);
    }
  }, [carid, wheelid]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
      const vh = window.innerHeight;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (progress !== 100 || loaded === 0) {
      setShowDelayLoading(true)
      if (onloading) {
        onloading({
          detail: true
        })
      }
    }
  }, [progress, loaded])

  useEffect(() => {
    if (background === null || background === undefined) {
      return;
    }
    handleSelectBg(parseInt(background));
  }, [background])

  useEffect(() => {
    if (autorotate === null || autorotate === undefined) {
      return;
    }
    setCameraAutoRotate(JSON.parse(autorotate));
  }, [autorotate]);

  useEffect(() => {
    if (color) {
      setCarColor(color)
    }
  }, [color]);

  useEffect(() => {
    if (paint !== null && paint !== undefined) {
      setSelectedPaint(parseInt(paint))
    }
  }, [paint]);

  useEffect(() => {
    if (susval !== null && susval !== undefined) {
      const lowest = -0.06;
      const maxRange = 10;
      const calculated = lowest * ((maxRange - parseInt(susval))/maxRange)
      setCarSuspension(calculated)
    }
  }, [susval]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  function setEventOnLoadingFalse() {
    if (onloading) {
      onloading({
        detail: false
      })
    }
  }
  function setDotsFromCar(event) {
    setDots(event);
  }
  function setTyreDepthFromCar(event) {
    setTyreDepth(event);
  }
  function setTyreNodesFromCar(event) {
    setTyreNodes(event);
  }
  function setWheelSizeAfterScaling(event) {
    setWheelSize(event)
  }
  function setHasDrumBrakeFromCar(event) {
    setHasDrumBrake(event)
  }
  function setShowDelayLoadingState(event) {
    setShowDelayLoading(event);
  }
  function handleColorChange(e) {
    setCarColor(e.hex)
  }
  function handleSuspensionChange(val) {
    setCarSuspension(val)
  }
  function handleToggleSuspension(toggle) {
    if (toggle === 'add' && carSuspension < 0) {
      setCarSuspension(
        parseFloat(
          ((carSuspension * 100 + 1) / 100).toFixed(2)
        )
      )
    } else if (toggle === 'minus' && carSuspension > -0.06) {
      setCarSuspension(
        parseFloat(
          ((carSuspension * 100 - 1) / 100).toFixed(2)
        )
      )
    }
  }

  function onCameraUpdate(e) {
    if (cameraControlsRef.current) {
      if (distanceCount.current > 8) {
        startDistance.current = e.target.distance;
        distanceCount.current = 0;
      } else {
        distanceCount.current += 1;
      }
      handleOnZoom(e.target.distance);
    }
  }

  const handleOnZoom = (distance) => {
    if (isMobileAndTabletControlVertical
      && distance > startDistance.current
      && startDistance.current < 6
    ) {
      endDistance.current = startDistance.current;
    } else if (distance > startDistance.current) {
      endDistance.current = startDistance.current;
    }
    if (endDistance.current !== 0 && (distance > endDistance.current && distance - endDistance.current > 0.5)) {
      setZoomOut();
    }
  };

  const setZoomOut = debounce(function() {
    endDistance.current = 0;
    cameraControlsRef.current.dolly(-5, true);
    cameraControlsRef.current.setTarget(0, 0, 0, true);
  }, 500);

  function Loader() {
    return (
      <img src={jflogo} alt="jflogo" className="animate-flicker" />
    )
  }
  function LoadingScreen() {
    return (
      <div className="loading-overlay screen show">
        <Loader />
      </div>
    );
  }
  function PaintOption() {
    return <ul className="flex w-full mt-4">
      <li
        className={
          "rounded-l grow border p-1 text-center font-serpentine text-[12px] cursor-pointer" +
          (selectedPaint === 0 ? ' text-white bg-[#535760] border-[#535760]' : ' text-[#535760] bg-white border-[#dadada]')
        }
        onClick={() => setSelectedPaint(0)}
      >
        GLOSS
      </li>
      <li
        className={
          "rounded-r grow border border-l-0 border-[#dadada] p-1 text-center font-serpentine text-[12px] cursor-pointer" +
          (selectedPaint === 1 ? ' text-white bg-[#535760] border-[#535760]' : ' text-[#535760] bg-white border-[#dadada]')
        }
        onClick={() => setSelectedPaint(1)}
      >
        MATTE
      </li>
    </ul>
  }

  return (
    <div className="relative Configurator">
      <Suspense fallback={<LoadingScreen />}>
        <Canvas
          gl={{
            antialias: true,
            toneMappingExposure: 0.7,
            pixelRatio: Math.min(window.devicePixelRatio, 2),
          }}
          shadows={THREE.VSMShadowMap}
          id="three-canvas"
        >
          <group position={[0, -0.4, 0]}>
            <WheelAndDots
              wheelFile={WHEEL_OPTIONS_LIST[selectedWheelIndex].file}
              selectedCarIndex={selectedCarIndex}
              selectedWheelIndex={selectedWheelIndex}
              tyreDepth={tyreDepth}
              tyreNodes={tyreNodes}
              wheelScale={wheelScale}
              dots={dots}
              hasDrumBrake={hasDrumBrake}
              setWheelSize={setWheelSizeAfterScaling}
              setEventLoading={setEventOnLoadingFalse}
              setShowDelayLoading={setShowDelayLoadingState}
            />
            <Car
              modelFile={CAR_OPTIONS_LIST[selectedCarIndex].file}
              carId={CAR_OPTIONS_LIST[selectedCarIndex].key}
              suspension={carSuspension}
              color={carColor}
              selectedPaint={selectedPaint}
              wheelScale={wheelScale}
              wheelSize={wheelSize}
              setDots={setDotsFromCar}
              setTyreDepth={setTyreDepthFromCar}
              setTyreNodes={setTyreNodesFromCar}
              setHasDrumBrake={setHasDrumBrakeFromCar}
            />
            { showBg ? <Background modelFile={BG_LIST[selectedBgIndex].bg} /> : <></> }
          </group>
          <hemisphereLight intensity={0.5} />
          <PerspectiveCamera
            ref={camRef}
            fov={isMobileAndTabletControlVertical ? 45 : 29.5}
            position={
              isMobileAndTabletControlVertical
              ? [-9.708949167153316, 0.8967611230370927, 6.995150145482383]
              : [-5.254570953801076, 0.7867185542086159, 7.418483454878853]
            }
            makeDefault
          />
          <CameraControls
            ref={cameraControlsRef}
            minPolarAngle={1.1}
            maxPolarAngle={Math.PI / 2.1}
            minDistance={isMobileAndTabletControlVertical ? 3.2 : 3.78}
            maxDistance={isMobileAndTabletControlVertical ? 13 : 12}
            dollyToCursor={true}
            dollySpeed={0.5}
            smoothTime={0.09}
            draggingSmoothTime ={0.15}
            truckSpeed={0}
            onChange={onCameraUpdate}
          />
          {
            showBg
              ? <>
                <EnvBuilder
                  height={BG_LIST[selectedBgIndex].ground.height}
                  radius={BG_LIST[selectedBgIndex].ground.radius}
                  scale={BG_LIST[selectedBgIndex].ground.scale}
                  hdrJpg={BG_LIST[selectedBgIndex].hdrJpg}
                  showBg={showBg}
                  setEventLoading={setEventOnLoadingFalse}
                  setShowDelayLoading={setShowDelayLoadingState}
                />
                <Environment
                  background={false}
                  files={BG_LIST[selectedBgIndex].hdri}
                />
              </>
              : <Environment
                background={false} // Whether to affect scene.background
                files={HDRI_LIST['canary_wharf']}
              />
          }
        </Canvas>
      </Suspense>
      {
        showDelayLoading || (background === null || background === undefined) || iscustom
          ? <></>
          : <>
            <ClickOutside func={() => setShowBgList(false)}>
              <div
                  className={
                    "absolute left-4 bottom-4 z-20 transition-all duration-300 ease-in-out"
                }
              >
                <div className="relative">
                  <button
                    className="w-10 h-10 xl:w-16 xl:h-16 min-h-5 bg-white shadow-md rounded-full flex items-center justify-center"
                    onClick={() => setShowBgList(!showBgList)}
                  >
                    <IconGallery />
                  </button>
                </div>
                  <ul
                    className={
                      "absolute flex items-center gap-2 transition-all duration-300 ease-in-out" +
                      " md:flex-row md:left-full md:bottom-1/2 md:transform md:translate-x-2 md:translate-y-1/2" +
                      (isMobileAndTabletHorizontal ? ' flex-row left-full bottom-1/2 transform translate-x-2 translate-y-1/2' : '  bottom-full left-0 transform -translate-y-2 flex flex-col') +
                      (showBgList ? ' opacity-100 visible' : '  opacity-0 invisible')
                    }
                  >
                    {
                      BG_LIST.map((item, index) => {
                        if (item.key === 'empty') {
                          return <li
                            key={'bg' + item.key}
                            className={
                              "w-24 h-16 border border-[#535760] shadow cursor-pointer transition-all duration-400 ease-in-out bg-white" +
                              (selectedBgIndex !== index ? ' opacity-100 hover:opacity-60' : ' border-2 opacity-100 pointer-events-none')
                            }
                            onClick={() => handleSelectBg(index)}
                          >
                          </li>
                        } else {
                          return <li
                            key={'bg' + item.key}
                            className={
                              "w-24 h-16 border border-[#535760] shadow cursor-pointer transition-all duration-400 ease-in-out" +
                              (selectedBgIndex !== index ? ' opacity-100 hover:opacity-60' : ' border-2 border-[#f79736] pointer-events-none')
                            }
                            onClick={() => handleSelectBg(index)}
                          >
                            <img src={BG_LIST[index].img} alt={item.key} className="w-full h-full object-cover" />
                          </li>
                        }
                      })
                    }
                  </ul>
              </div>
            </ClickOutside>
          </>
        }
        {
        showDelayLoading || iscustom
          ? <></>
          : <>
            <div
              className={
                "absolute right-4 bottom-4 z-20 flex flex-col items-center gap-y-3 transition-all duration-300 ease-in-out"
              }
            >
              {
                autorotate !== null || autorotate !== undefined
                  ? <button
                      className={
                        "relative z-10 w-10 h-10 xl:w-16 xl:h-16 min-h-5 bg-white shadow-md rounded-full flex items-center justify-center bg-btn" +
                        (cameraAutoRotate ? ' show' : '')
                      }
                      onClick={() => setCameraAutoRotate(!cameraAutoRotate)}
                    >
                      <IconThreesixty className="xl:w-6 xl:h-6" />
                    </button>
                  : <></>
              }
              {
                suspension
                  ? <ClickOutside func={() => setShowCarSuspension(false)}>
                    <div className="relative">
                      <button
                        className="relative z-10 w-10 h-10 xl:w-16 xl:h-16 min-h-5 bg-white shadow-md rounded-full flex items-center justify-center"
                        onClick={() => setShowCarSuspension(!showCarSuspension)}
                      >
                        <IconSuspension className="xl:w-6 xl:h-6" />
                      </button>
                      <div
                        className={
                          "absolute h-[160px] z-20 right-full top-1/2 transform -translate-x-4 translate-y-[-50%] transition-opacity duration-300 ease-in-out py-2 px-5 rounded-md filter backdrop-blur shadow flex flex-col items-center" +
                          (showCarSuspension ? " opacity-100" : " opacity-0 invisible pointer-none")
                        }
                      >
                        <button
                          className={(showBg ? 'text-white' : 'text-[#535760]' ) + " font-serpentine text-[2rem] leading-none"}
                          onClick={() => handleToggleSuspension('add')}
                        >
                          +
                        </button>
                        <Slider
                          min={-0.06}
                          max={0}
                          value={carSuspension}
                          tooltip={false}
                          orientation='vertical'
                          step={0.01}
                          onChange={handleSuspensionChange}
                        />
                        <button
                          className={(showBg ? 'text-white' : 'text-[#535760]' ) + " font-serpentine text-[1.2rem] leading-none"}
                          onClick={() => handleToggleSuspension('minus')}
                        >
                          -
                        </button>
                      </div>
                    </div>
                  </ClickOutside>
                  : <></>
              }
              <ClickOutside func={() => setShowColorPicker(false)}>
                {
                  colorlist
                    ? <div className="relative">
                      <button
                        className="relative z-10 w-10 h-10 xl:w-16 xl:h-16 min-h-5 bg-white shadow rounded-full flex items-center justify-center"
                        onClick={() => setShowColorPicker(!showColorPicker)}
                      >
                        <IconSpray className="relative right-[1px] xl:w-7 xl:h-7" />
                      </button>
                      <ul
                        className={
                          "absolute z-20 bottom-0 right-full transform -translate-x-4 transition-opacity duration-300 ease-in-out flex flex-col gap-2 p-2 rounded-md filter backdrop-blur shadow" +
                          (showColorPicker ? " opacity-100" : " opacity-0 invisible pointer-none")
                        }
                      >
                        {
                          colorOptionArray.map((colorOpt, index) =>
                            <li
                              key={'colorOption' + index}
                              className={
                                "w-4 h-4 rounded-full transition-all duration-300 ease-in-out cursor-pointer border" +
                                (carColor === colorOpt.value ? ' transform scale-110 pointer-events-none border-[#f79736]' : ' transform scale-100 border-transparent')
                              }
                              style={{
                                backgroundColor: colorOpt.display
                              }}
                              onClick={() => setCarColor(colorOpt.value)}
                            >
                            </li>
                          )
                        }
                      </ul>
                    </div>
                    : <div className="relative">
                      { ((paint !== null && paint !== undefined) && document.querySelector('label[for^=rc-editable]')) && createPortal(<PaintOption />, document.querySelector('label[for^=rc-editable]').parentElement) }
                      <button
                        className="relative z-10 w-10 h-10 xl:w-16 xl:h-16 min-h-5 bg-white shadow rounded-full flex items-center justify-center"
                        onClick={() => setShowColorPicker(!showColorPicker)}
                      >
                        <IconSpray className="relative right-[1px] xl:w-7 xl:h-7" />
                      </button>
                      <ChromePicker
                        className={
                          "absolute z-20 bottom-0 right-full transform -translate-x-4 transition-opacity duration-300 ease-in-out" +
                          (showColorPicker ? " opacity-100" : " opacity-0 invisible pointer-none")
                        }
                        color={carColor}
                        onChange={handleColorChange}
                      />
                    </div>
                }
              </ClickOutside>
            </div>
          </>
        }
    </div>
  );
}

ConfiguratorOne.propTypes = {
  color: PropTypes.string,
  colorlist: PropTypes.string,
  paint: PropTypes.number,
  suspension: PropTypes.number,
  autorotate: PropTypes.bool,
  background: PropTypes.number,
  onloading: PropTypes.func,
  carid: PropTypes.string,
  wheelid: PropTypes.string,
  diameter: PropTypes.string
};

export default ConfiguratorOne;
