import { useGLTF } from '@react-three/drei';

function Background(props) {
  const {
    modelFile,
  } = props;
  const { scene } = useGLTF(modelFile, '/draco/gltf/')

  return (
    <primitive object={scene} />
  );
}

export default Background;
