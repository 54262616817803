import { Suspense, useState, useMemo, useEffect, useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import {
  OrbitControls,
  PerspectiveCamera,
  Environment,
  useProgress,
  useGLTF,
} from '@react-three/drei';
import PropTypes from 'prop-types';

import jflogo from '../../assets/jflogo.png';

const HDRI_LIST = {
  'canary_wharf': require('../../assets/hdri/canary_wharf_2k.hdr'),
};

const WHEEL_OPTIONS_LIST = [
  {
    key: 'itwheels-amos',
    img: require('../../assets/amos.jpg'),
    file: require('../../assets/wheels/itwheels/amos_18_gloss-black-polished.glb'),
  },
  {
    key: 'bbs-lm',
    img: require('../../assets/LM.png'),
    file: require('../../assets/wheels/bbs/lm_18_brillantsilber-felge-diagedr.glb'),
  },
  {
    key: 'bbs-chr',
    img: require('../../assets/CH-R.png'),
    file: require('../../assets/wheels/bbs/ch-r2_18_bronze-matt-schwarz.glb'),
  },
  {
    key: 'bbs-cir',
    img: require('../../assets/CI-R.png'),
    file: require('../../assets/wheels/bbs/ci-r_18_nurburgring-edition.glb'),
  },
  {
    key: 'itwheels-michelle',
    img: require('../../assets/michelle.jpg'),
    file: require('../../assets/wheels/itwheels/michelle_18_matt-black.glb'),
  },
  {
    key: 'itwheels-emily',
    img: require('../../assets/emily.jpg'),
    file: require('../../assets/wheels/itwheels/emily_18_gloss-silver.glb'),
  },
]

function WheelZoom({
  wheelid,
  onloading
}) {
  const camRef = useRef();
  const [showDelayLoading, setShowDelayLoading] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const { progress, loaded } = useProgress();

  const isMobileAndTabletControlVertical = useMemo(() => windowSize.innerWidth < 800 && window.matchMedia('(orientation: portrait)').matches, [windowSize.innerWidth]);

  function Model() {
    const itemFile = WHEEL_OPTIONS_LIST.find(item => item.key === wheelid).file;
    const { scene: wheelScene } = useGLTF(itemFile, '/draco/gltf/')
    return (<primitive object={wheelScene} />)
  }
  function Loader() {
    return (
      <img src={jflogo} alt="jflogo" className="animate-flicker" />
    )
  }
  function LoadingScreen() {
    return (
      <div className="loading-overlay screen show">
        <Loader />
      </div>
    );
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (progress === 100 && loaded > 0) {
      if (onloading) {
        onloading({
          detail: true
        })
      }
      setShowDelayLoading(true)
      setShowDelayLoadingWithDelay({
        status: false,
        delay: 500
      });
    } else {
      setShowDelayLoading(true)
      if (onloading) {
        onloading({
          detail: true
        })
      }
    }
  }, [progress, loaded])

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  function setShowDelayLoadingWithDelay({ status, delay }) {
    if (!delay) {
      setShowDelayLoading(status)
      if (onloading) {
        onloading({
          detail: status
        })
      }
      return;
    }
    setTimeout(() => {
      setShowDelayLoading(status)
      if (onloading) {
        onloading({
          detail: status
        })
      }
    }, delay)
  }

  return (
    <div className="Configurator">
      <div className={'loading-overlay' + (showDelayLoading ? ' show' : '')}>
        <img src={jflogo} alt="jflogo" />
      </div>
      <Suspense fallback={LoadingScreen}>
        <Canvas
          gl={{
            antialias: true,
            toneMappingExposure: 0.7,
            pixelRatio: Math.min(window.devicePixelRatio, 2),
          }}
          id="three-canvas"
        >
          <PerspectiveCamera
            ref={camRef}
            fov={isMobileAndTabletControlVertical ? 40 : 20}
            makeDefault
            position={[-0.07279424141991943, 0.07593999195817443, 2.198117655517967]}
          />
          <Model />
          <hemisphereLight intensity={0.5} />
          <OrbitControls
            enablePan={true}
            enableZoom={true}
            minPolarAngle={0}
            maxPolarAngle={Math.PI}
            minDistance={1}
            maxDistance={3}
          />
          <Environment
            background={false} // Whether to affect scene.background
            files={HDRI_LIST['canary_wharf']}
          />
        </Canvas>
      </Suspense>
    </div>
  );
}

WheelZoom.propTypes = {
  onloading: PropTypes.func,
  wheelid: PropTypes.string,
};

export default WheelZoom;
