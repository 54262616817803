import { useEffect } from 'react';
import { useLoader, useThree } from "@react-three/fiber";
import { useProgress } from '@react-three/drei'
import * as THREE from "three";
import { GroundProjectedEnv } from "three/examples/jsm/objects/GroundProjectedEnv";

export function EnvBuilder({
  hdrJpg,
  height,
  radius,
  scale,
  setShowDelayLoading,
  setEventLoading,
  showBg
}) {
  const { progress, loaded } = useProgress()
  const three = useThree();
  const texture = useLoader(THREE.TextureLoader, hdrJpg);
  texture.encoding = THREE.sRGBEncoding;
  texture.generateMipmaps = false;
  const env = new GroundProjectedEnv(texture);
  env.height = height;
  env.radius = radius;
  env.scale.setScalar(scale);

  useEffect(() => {
    if (progress === 100 && loaded > 0 && setShowDelayLoading) {
      setShowDelayLoading(false)
      if (setEventLoading) {
        setEventLoading()
      }
    }
  }, [progress, loaded])

  useEffect(() => {
    if (showBg) {
      three.scene.add(env);
    } else {
      three.scene.remove(env);
    }
  }, [showBg, env])

  useEffect(() => {
    return () => {
      three.scene.remove(env);
    };
  });
  return <></>;
}